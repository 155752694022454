import React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import AppAppBar from './AppAppBar';
import Footer from './Footer';

const termsOfServiceText = `Terms of Service
Welcome to MGH Global Retail! By accessing and using our website (www.mghglobalretail.com), you agree to comply with and be bound by the following terms and conditions. Please read them carefully.

1. Acceptance of Terms
By using this site, you acknowledge that you have read, understood, and agree to be bound by these terms and conditions, as well as our Privacy Policy. If you do not agree with any part of these terms, you must not use our website.

2. Changes to Terms
MGH Global Retail reserves the right to modify these terms at any time. Any changes will be effective immediately upon posting on the website. Your continued use of the website following the posting of changes constitutes your acceptance of such changes.

3. Use of the Website
You agree to use the website for lawful purposes only. You are prohibited from using the site to:

Post or transmit any unlawful, harmful, threatening, abusive, harassing, defamatory, vulgar, obscene, or otherwise objectionable content.
Impersonate any person or entity or falsely state or otherwise misrepresent your affiliation with a person or entity.
Engage in any activity that could damage, disable, overburden, or impair the website or interfere with any other party's use and enjoyment of the website.
4. Intellectual Property
All content on this website, including text, graphics, logos, images, and software, is the property of MGH Global Retail or its content suppliers and is protected by international copyright laws. You may not reproduce, distribute, or create derivative works based on any content without the express written consent of MGH Global Retail.

5. Third-Party Links
Our website may contain links to third-party websites. These links are provided for your convenience only. MGH Global Retail does not endorse or assume any responsibility for the content or practices of these third-party sites. Accessing any third-party sites linked to this website is done at your own risk.

6. Disclaimer of Warranties
MGH Global Retail provides the website on an "as is" and "as available" basis. We make no warranties, expressed or implied, regarding the website's operation or the information, content, or materials included on the website. To the fullest extent permissible by applicable law, we disclaim all warranties, express or implied, including but not limited to implied warranties of merchantability and fitness for a particular purpose.

7. Limitation of Liability
MGH Global Retail shall not be liable for any damages of any kind arising from the use of this website, including but not limited to direct, indirect, incidental, punitive, and consequential damages.

8. Governing Law
These terms and conditions are governed by and construed in accordance with the laws of the jurisdiction in which MGH Global Retail operates. Any disputes arising out of or relating to these terms shall be resolved exclusively in the courts of that jurisdiction.

9. Contact Information
If you have any questions about these Terms of Service, please contact us at support@mghglobalretail.com.

By using our website, you acknowledge that you have read these terms of service, understand them, and agree to be bound by them.`;

export default function TermsOfService({ mode, toggleColorMode }) {
  const theme = useTheme();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppAppBar mode={mode} toggleColorMode={toggleColorMode} sections={[]} isBlog={false} />
      <Container maxWidth="md" sx={{ mt: 16 }}>
        <Box sx={{ my: 4 }}>
          <Typography variant="h4" gutterBottom>
            Terms of Service
          </Typography>
          <Typography variant="body1" paragraph>
            {/* Terms of service text goes here */}
            {termsOfServiceText.split('\n').map((text, index) => (
              <span key={index}>
                {text}
                <br />
              </span>
            ))}
          </Typography>
        </Box>
      </Container>
      <Footer
        title="Footer"
        description="Something here to give the footer a purpose!"
      />
    </ThemeProvider>
  );
}
