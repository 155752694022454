import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

const StyledBox = styled('div')(({ theme }) => ({
  alignSelf: 'center', // Center the box
  width: '100%', // Set the width to 100%
  height: 400, // Set the initial height
  marginTop: theme.spacing(8), // Set the top margin
  borderRadius: theme.shape.borderRadius, // Set the border radius
  outline: '1px solid', // Set the outline
  boxShadow: '0 0 12px 8px hsla(220, 25%, 80%, 0.2)', // Set the box shadow
  backgroundImage: `url("/images/amazon_pan_eu.png")`, // Set the background image path
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center', // Center the background image
  outlineColor: 'hsla(220, 25%, 80%, 0.5)', // Set the outline color
  [theme.breakpoints.up('sm')]: {
    marginTop: theme.spacing(10), // Adjust top margin for larger screens
    height: 700, // Adjust height for larger screens
  },
  ...theme.applyStyles('dark', {
    boxShadow: '0 0 24px 12px hsla(210, 100%, 25%, 0.2)', // Adjust box shadow for dark theme
    backgroundImage: `url("/images/amazon_pan_eu.png")`, // Adjust background image path for dark theme
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center', // Center the background image
    outlineColor: 'hsla(210, 100%, 80%, 0.1)', // Adjust outline color for dark theme
  }),
}));

export default function Hero() {
  return (
    <Box
      id="hero"
      sx={(theme) => ({
        width: '100%',
        backgroundRepeat: 'no-repeat',
        backgroundImage:
          'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 90%), transparent)',
        ...theme.applyStyles('dark', {
          backgroundImage:
            'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 16%), transparent)',
        }),
      })}
    >
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: { xs: 14, sm: 20 },
          pb: { xs: 8, sm: 12 },
        }}
      >
        <Stack
          spacing={2}
          useFlexGap
          sx={{ alignItems: 'center', width: { xs: '100%', sm: '70%' } }}
        >
          <Typography
            variant="h1"
            sx={{
              fontSize: 'clamp(3rem, 10vw, 3.5rem)',
              textAlign: 'center',
            }}
          >
            We&nbsp;
            <Box
              component="span"
              color="info"
              sx={(theme) => ({
                color: theme.palette.mode === 'dark' ? 'primary.main' : 'primary.main',
              })}
            >
              grow your brand&nbsp;
            </Box>
            across Europe
          </Typography>
          <Box sx={{ textAlign: 'center', color: 'text.secondary', width: { sm: '100%', md: '80%' } }}>
            <Typography variant="h6">
              Distributing branded products on European market since 2018.
            </Typography>
            <Typography variant="h6">
              Partner with us to expand your brand’s reach with Amazon's pan-European program and increase your market share.
            </Typography>
          </Box>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={1}
            useFlexGap
            sx={{ pt: 2, width: { xs: '100%', sm: 'auto' } }}
          >
            <Button
              component={RouterLink}
              to="/contact"
              variant="contained"
              color="primary"
            >
              Contact Us
            </Button>
          </Stack>
        </Stack>
        <StyledBox id="image" />
      </Container>
    </Box>
  );
}
