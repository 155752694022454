import * as React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import ToggleColorMode from './ToggleColorMode';

function AppAppBar({ mode, toggleColorMode, sections, isBlog }) {
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();

  // Function to toggle the drawer open/close state
  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  // Function to scroll to a specific section on the page
  const scrollToSection = (sectionId) => {
    navigate(`/#${sectionId}`);
    const sectionElement = document.getElementById(sectionId);
    const offset = 128;
    if (sectionElement) {
      const targetScroll = sectionElement.offsetTop - offset;
      sectionElement.scrollIntoView({ behavior: 'smooth' });
      window.scrollTo({
        top: targetScroll,
        behavior: 'smooth',
      });
      setOpen(false);
    }
  };

  return (
    <AppBar
      position="fixed"
      sx={{ boxShadow: 0, bgcolor: 'transparent', backgroundImage: 'none', mt: 2 }}
    >
      <Container maxWidth="lg">
        <Toolbar
          variant="regular"
          sx={(theme) => ({
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexShrink: 0,
            borderRadius: '999px',
            backdropFilter: 'blur(24px)',
            maxHeight: 40,
            border: '1px solid',
            borderColor: 'divider',
            bgcolor: 'hsla(220, 60%, 99%, 0.6)',
            boxShadow:
              '0 1px 2px hsla(210, 0%, 0%, 0.05), 0 2px 12px hsla(210, 100%, 80%, 0.5)',
            // Apply styles based on the theme mode (dark/light)
            ...theme.applyStyles('dark', {
              bgcolor: 'hsla(220, 0%, 0%, 0.7)',
              boxShadow:
                '0 1px 2px hsla(210, 0%, 0%, 0.5), 0 2px 12px hsla(210, 100%, 25%, 0.3)',
            }),
          })}
        >
          <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', px: 0 }}>
            <RouterLink to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
              <Box
                component="img"
                src="/logo_mgh.png"
                alt="MGH Global Retail"
                sx={{ height: 40, display: 'flex', alignItems: 'center', pl: 1 }}
              />
            </RouterLink>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'center' }}>
              {isBlog ? (
                sections.map((section) => (
                  <Button
                    key={section.title}
                    variant="text"
                    color="info"
                    size="small"
                    component={RouterLink}
                    to={section.url}
                  >
                    {section.title}
                  </Button>
                ))
              ) : (
                <>
                  <Button
                    variant="text"
                    color="info"
                    size="small"
                    onClick={() => scrollToSection('services')}
                  >
                    Services
                  </Button>
                  <Button
                    variant="text"
                    color="info"
                    size="small"
                    onClick={() => scrollToSection('testimonials')}
                  >
                    Testimonials
                  </Button>
                  <Button
                    variant="text"
                    color="info"
                    size="small"
                    onClick={() => scrollToSection('highlights')}
                  >
                    Highlights
                  </Button>
                  <Button
                    variant="text"
                    color="info"
                    size="small"
                    onClick={() => scrollToSection('faq')}
                    sx={{ minWidth: 0 }}
                  >
                    FAQ
                  </Button>
                  {/* <Button
                    component={RouterLink}
                    to="/blog"
                    variant="text"
                    color="info"
                    size="small"
                    sx={{ minWidth: 0 }}
                  >
                    BLOG
                  </Button> */}
                </>
              )}
            </Box>
          </Box>
          <Box
            sx={{
              display: { xs: 'none', md: 'flex' },
              gap: 0.5,
              alignItems: 'center',
            }}
          >
            <ToggleColorMode
              data-screenshot="toggle-mode"
              mode={mode}
              toggleColorMode={toggleColorMode}
            />
            <Button
              component={RouterLink}
              to="/contact"
              variant="outlined"
              color="primary"
            >
              Contact Us
            </Button>
          </Box>
          <Box sx={{ display: { sm: 'flex', md: 'none' } }}>
            <IconButton aria-label="Menu button" onClick={toggleDrawer(true)}>
              <MenuIcon />
            </IconButton>
            <Drawer anchor="top" open={open} onClose={toggleDrawer(false)}>
              <Box sx={{ p: 2, backgroundColor: 'background.default' }}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <ToggleColorMode mode={mode} toggleColorMode={toggleColorMode} />
                  <IconButton onClick={toggleDrawer(false)}>
                    <CloseRoundedIcon />
                  </IconButton>
                </Box>
                <Divider sx={{ my: 3 }} />
                {isBlog ? (
                  sections.map((section) => (
                    <MenuItem key={section.title} component={RouterLink} to={section.url}>
                      {section.title}
                    </MenuItem>
                  ))
                ) : (
                  <>
                    <MenuItem onClick={() => scrollToSection('services')}>
                      Services
                    </MenuItem>
                    <MenuItem onClick={() => scrollToSection('testimonials')}>
                      Testimonials
                    </MenuItem>
                    <MenuItem onClick={() => scrollToSection('highlights')}>
                      Highlights
                    </MenuItem>
                    <MenuItem onClick={() => scrollToSection('pricing')}>
                      Pricing
                    </MenuItem>
                    <MenuItem onClick={() => scrollToSection('faq')}>FAQ</MenuItem>
                  </>
                )}
              </Box>
            </Drawer>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

AppAppBar.propTypes = {
  mode: PropTypes.oneOf(['dark', 'light']).isRequired,
  toggleColorMode: PropTypes.func.isRequired,
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    })
  ),
  isBlog: PropTypes.bool, // Prop to indicate if it's the blog page
};

AppAppBar.defaultProps = {
  sections: [], // Default sections to an empty array
  isBlog: false, // Default isBlog to false
};

export default AppAppBar;
