import React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { useTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import AppAppBar from './AppAppBar'; // Ensure the correct path to AppAppBar
import Footer from './Footer'; // Ensure the correct path to Footer

const privacyPolicyText = `
Your privacy is important to us. It is MGH Global Retail's policy to respect your privacy regarding any information we may collect from you across our website, https://mghglobalretail.com, and other sites we own and operate.

1. Information we collect
- Log data
- Personal information
- Cookies

2. How we use your information
- To provide and maintain our service
- To notify you about changes to our service
- To provide customer support

3. Sharing your information
- Third-party service providers
- Compliance with laws

4. Security of your information
- Protecting your personal data
- Security measures

5. Your data protection rights
- The right to access
- The right to rectification
- The right to erasure
- The right to restrict processing

By using our services, you agree to the collection and use of information in accordance with this policy.
`;

export default function PrivacyPolicy({ mode, toggleColorMode }) {
  const theme = useTheme();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppAppBar mode={mode} toggleColorMode={toggleColorMode} sections={[]} isBlog={false} />
      <Container maxWidth="md" sx={{ mt: 16 }}>
        <Box sx={{ my: 4 }}>
          <Typography variant="h4" gutterBottom>
            Privacy Policy
          </Typography>
          <Typography variant="body1" paragraph>
            {privacyPolicyText.split('\n').map((text, index) => (
              <span key={index}>
                {text}
                <br />
              </span>
            ))}
          </Typography>
        </Box>
      </Container>
      <Divider />
      <Footer />
    </ThemeProvider>
  );
}
