import React from 'react'; // Importing React library
import ReactDOM from 'react-dom/client'; // Importing ReactDOM for rendering
import { BrowserRouter as Router } from 'react-router-dom'; // Importing BrowserRouter for routing
import App from './App'; // Importing the main App component
import './index.css'; // Importing global CSS

// Create a root for React to render into
const root = ReactDOM.createRoot(document.getElementById('root'));

// Render the App component within a Router for routing support
root.render(
  <Router>
    <App />
  </Router>
);
