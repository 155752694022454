import React from 'react'; // Importing React library
import { ThemeProvider, createTheme } from '@mui/material/styles'; // Importing ThemeProvider and createTheme for theming
import CssBaseline from '@mui/material/CssBaseline'; // Importing CssBaseline for global styles reset
import Box from '@mui/material/Box'; // Importing Box component for layout
import Divider from '@mui/material/Divider'; // Importing Divider component
import AppAppBar from '../components/AppAppBar'; // Importing AppAppBar component
import Hero from '../components/Hero'; // Importing Hero component
import Highlights from '../components/Highlights'; // Importing Highlights component
import Features from '../components/Features'; // Importing Features component
import Testimonials from '../components/Testimonials'; // Importing Testimonials component
import FAQ from '../components/FAQ'; // Importing FAQ component
import Footer from '../components/Footer'; // Importing Footer component

export default function LandingPage({ mode, toggleColorMode }) { // Accept mode and toggleColorMode as props
  const defaultTheme = createTheme({ palette: { mode } }); // Create a theme with the current mode

  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline /> {/* Apply global styles reset */}
      <AppAppBar mode={mode} toggleColorMode={toggleColorMode} /> {/* App bar with theme toggle */}
      <Hero /> {/* Hero section */}
      <Box sx={{ bgcolor: 'background.default' }}>
        <Features /> {/* Features section */}
        <Divider /> {/* Divider for separation */}
        <Testimonials /> {/* Testimonials section */}
        <Divider />
        <Highlights /> {/* Highlights section */}
        <Divider />
        <FAQ /> {/* FAQ section */}
        <Divider />
        <Footer /> {/* Footer section */}
      </Box>
    </ThemeProvider>
  );
}
