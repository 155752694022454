import * as React from 'react'; // Importing React
import Box from '@mui/material/Box'; // Importing Box component from Material UI
import Button from '@mui/material/Button'; // Importing Button component from Material UI
import Card from '@mui/material/Card'; // Importing Card component from Material UI
import { Chip as MuiChip } from '@mui/material'; // Importing Chip component from Material UI
import Container from '@mui/material/Container'; // Importing Container component from Material UI
import Grid from '@mui/material/Grid'; // Importing Grid component from Material UI
import Stack from '@mui/material/Stack'; // Importing Stack component from Material UI
import Typography from '@mui/material/Typography'; // Importing Typography component from Material UI

import { styled } from '@mui/material/styles'; // Importing styled function from Material UI

import CakeRoundedIcon from '@mui/icons-material/CakeRounded'; // Importing CakeRoundedIcon icon from Material UI
import AssuredWorkloadRoundedIcon from '@mui/icons-material/AssuredWorkloadRounded'; // Importing AssuredWorkloadRoundedIcon icon from Material UI
import QueryStatsRoundedIcon from '@mui/icons-material/QueryStatsRounded'; // Importing QueryStatsRoundedIcon icon from Material UI

// Array of feature items with icons, titles, descriptions, and images
const items = [
  {
    icon: <QueryStatsRoundedIcon />,
    title: 'Listing analytics & optimisation',
    description:
      'A listing is the first impression of your product. We make sure it is optimised for maximum visibility and conversion.',
    imageLight: 'url("/static/images/templates/templates-images/dash-light.png")',
    imageDark: 'url("/static/images/templates/templates-images/dash-dark.png")',
  },
  {
    icon: <AssuredWorkloadRoundedIcon />,
    title: 'Amazon regulations compliance',
    description:
      'We stay up to date with the latest Amazon regulations and ensure that your listings are always compliant.',
    imageLight: 'url("/static/images/templates/templates-images/mobile-light.png")',
    imageDark: 'url("/static/images/templates/templates-images/mobile-dark.png")',
  },
  {
    icon: <CakeRoundedIcon />,
    title: 'PPC advertising',
    description:
      'We set up and manage PPC campagne to drive traffic and increase market share on relevant keywords.',
    imageLight: 'url("/static/images/templates/templates-images/devices-light.png")',
    imageDark: 'url("/static/images/templates/templates-images/devices-dark.png")',
  },
];

// Styling the MuiChip component
const Chip = styled(MuiChip)(({ theme }) => ({
  variants: [
    {
      props: ({ selected }) => selected,
      style: {
        background:
          'linear-gradient(to bottom right, hsl(210, 98%, 48%), hsl(210, 98%, 35%))',
        color: 'hsl(0, 0%, 100%)',
        borderColor: theme.palette.primary.light,
        '& .MuiChip-label': {
          color: 'hsl(0, 0%, 100%)',
        },
        ...theme.applyStyles('dark', {
          borderColor: theme.palette.primary.dark,
        }),
      },
    },
  ],
}));

export default function Features() {
  const [selectedItemIndex, setSelectedItemIndex] = React.useState(0); // State to manage the selected feature

  // Function to handle item click
  const handleItemClick = (index) => {
    setSelectedItemIndex(index);
  };

  // Get the selected feature based on the index
  const selectedFeature = items[selectedItemIndex];

  return (
    <Container id="services" sx={{ py: { xs: 8, sm: 16 } }}>
        <Grid item xs={12} md={6}> 
          <div>
            <Typography component="h2" variant="h4" sx={{ color: 'text.primary' }}>
              The win-win approach
            </Typography>
            <Typography
              variant="body1"
              sx={{ color: 'text.secondary', mb: { xs: 2, sm: 4 } }}
            >
              We take care of everything from listing to customer service. You get a guaranteed sale and we get a profit margin. Win-win.
            </Typography>
          </div>
          <Grid container item sx={{ gap: 1, display: { xs: 'auto', sm: 'none' } }}>
            {/* Render chips for each feature */}
            {items.map(({ title }, index) => (
              <Chip
                key={index}
                label={title}
                onClick={() => handleItemClick(index)}
                selected={selectedItemIndex === index}
              />
            ))}
          </Grid>
          {/* Card for mobile size */}
          <Card
            variant="outlined"
            sx={{ display: { xs: 'auto', sm: 'none' }, mt: 4 }}
          >
            <Box sx={{ px: 2, py: 2 }}>
              <Typography
                gutterBottom
                sx={{ color: 'text.primary', fontWeight: 'medium' }}
              >
                {selectedFeature.title}
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary', mb: 1.5 }}>
                {selectedFeature.description}
              </Typography>
            </Box>
          </Card>
          <Stack
          direction="row"
          spacing={2}
          sx={{
            justifyContent: 'center', // Center the cards horizontally
            alignItems: 'stretch', // Ensure cards stretch to the same height
            width: '100%',
            display: { xs: 'none', sm: 'flex' }, // Hide on small screens, show on medium and larger screens
          }}
        >
          {/* Render cards for each feature */}
          {items.map(({ icon, title, description }, index) => (
            <Card
              key={index}
              component={Button}
              onClick={() => handleItemClick(index)}
              sx={[
                (theme) => ({
                  p: 3,
                  display: 'flex',
                  flexDirection: 'column', // Arrange content in a column
                  justifyContent: 'space-between', // Distribute space evenly
                  flexGrow: 1, // Allow cards to grow and fill available space
                  background: 'none',
                  '&:hover': {
                    background:
                      'linear-gradient(to bottom right, hsla(210, 100%, 97%, 0.5) 25%, hsla(210, 100%, 90%, 0.3) 100%)',
                    borderColor: 'primary.light',
                    boxShadow: '0px 2px 8px hsla(0, 0%, 0%, 0.1)',
                    ...theme.applyStyles('dark', {
                      background:
                        'linear-gradient(to right bottom, hsla(210, 100%, 12%, 0.2) 25%, hsla(210, 100%, 16%, 0.2) 100%)',
                      borderColor: 'primary.dark',
                      boxShadow: '0px 1px 8px hsla(210, 100%, 25%, 0.5) ',
                    }),
                  },
                }),
                selectedItemIndex === index &&
                  ((theme) => ({
                    backgroundColor: 'action.selected',
                    borderColor: 'primary.light',
                    ...theme.applyStyles('dark', {
                      borderColor: 'primary.dark',
                    }),
                  })),
              ]}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  textAlign: 'left',
                  gap: 2.5,
                }}
              >
                <Box
                  sx={[
                    (theme) => ({
                      color: 'grey.400',
                      ...theme.applyStyles('dark', {
                        color: 'grey.600',
                      }),
                    }),
                    selectedItemIndex === index && {
                      color: 'primary.main',
                    },
                  ]}
                >
                  {icon}
                </Box>
                <Typography
                  gutterBottom
                  sx={{ color: 'text.primary', fontWeight: 'medium' }}
                >
                  {title}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ color: 'text.secondary', mb: 1.5 }}
                >
                  {description}
                </Typography>
              </Box>
            </Card>
            ))}
          </Stack>
        </Grid>
    </Container>
  );
}
