import React, { useEffect } from 'react';
import { useLocation, Routes, Route } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import LandingPage from './pages/LandingPage';
import ContactPage from './pages/ContactPage';
// import Blog from './pages/blog/Blog';
// import BlogPost from './pages/blog/components/BlogPost';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsOfService from './components/TermsOfService';

function App() {
  const [mode, setMode] = React.useState('dark');
  const defaultTheme = createTheme({ palette: { mode } });

  const toggleColorMode = () => {
    setMode((prev) => (prev === 'dark' ? 'light' : 'dark'));
  };

  const location = useLocation();

  useEffect(() => {
    const handleHashChange = () => {
      const hash = location.hash;
      if (hash) {
        const sectionId = hash.substring(1);
        const sectionElement = document.getElementById(sectionId);
        const offset = 128;
        if (sectionElement) {
          const targetScroll = sectionElement.offsetTop - offset;
          window.scrollTo({
            top: targetScroll,
            behavior: 'smooth',
          });
        }
      } else {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    };

    handleHashChange();
  }, [location]);

  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <Routes>
        <Route path="/contact" element={<ContactPage mode={mode} toggleColorMode={toggleColorMode} />} />
        <Route path="/" element={<LandingPage mode={mode} toggleColorMode={toggleColorMode} />} />
        {/* <Route path="/blog" element={<Blog mode={mode} toggleColorMode={toggleColorMode} />} /> */}
        {/* <Route path="/blog/:postId" element={<BlogPost mode={mode} toggleColorMode={toggleColorMode} />} /> */}
        <Route path="/privacy-policy" element={<PrivacyPolicy mode={mode} toggleColorMode={toggleColorMode} />} />
        <Route path="/terms-of-service" element={<TermsOfService mode={mode} toggleColorMode={toggleColorMode} />} />
      </Routes>
    </ThemeProvider>
  );
}

export default App;
