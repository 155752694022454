import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

const userTestimonials = [
  {
    avatar: <Avatar alt="Julia Stewart" src="/static/images/avatar/4.jpg" />,
    name: 'Julia Stewart',
    occupation: 'Senior Engineer',
    testimonial:
      "Their insights have been a game changer for our sales strategy. It's like having a team of experts guiding us through every step of selling on Amazon.",
  },
  {
    avatar: <Avatar alt="Jean-Luc Moreau" src="/static/images/avatar/5.jpg" />,
    name: 'Jean-Luc Moreau',
    occupation: 'Marketing Manager',
    testimonial:
      "Hugo and his team have excellent communication. We always know what's happening, and their strategies have boosted our brand awareness significantly in new countries.",
  },
  {
    avatar: <Avatar alt="Daniel Wolf" src="/static/images/avatar/6.jpg" />,
    name: 'Daniel Wolf',
    occupation: 'Chief Digital Officer',
    testimonial:
      "The quality of insights provided is exceptional. Their detailed analysis has been crucial in refining our Amazon strategy and driving consistent growth in our market share.",
  },
  {
    avatar: <Avatar alt="Maria Garcia" src="/static/images/avatar/7.jpg" />,
    name: 'Maria Garcia',
    occupation: 'Brand Manager',
    testimonial:
      "Partnering with Hugo has been hassle-free. He handle everything, and we just see our order volume steadily increasing. It's a win-win.",
  },
  {
    avatar: <Avatar alt="Francois Dupont" src="/static/images/avatar/8.jpg" />,
    name: 'Francois Dupont',
    occupation: 'Sales Director',
    testimonial:
      "Navigating the complexities of Amazon became much easier. Their expertise in the European market is invaluable, and we've seen significant growth in order volumes.",
  },
  {
    avatar: <Avatar alt="Elena Rossi" src="/static/images/avatar/9.jpg" />,
    name: 'Elena Rossi',
    occupation: 'E-commerce Specialist',
    testimonial:
      "Thanks to their tailored strategies, our brand's presence on Amazon has grown tremendously. The support we've received is top-notch.",
  },
];


export default function Testimonials() {
  return (
    <Container
      id="testimonials"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Box
        sx={{
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' },
        }}
      >
        <Typography component="h2" variant="h4" sx={{ color: 'text.primary' }}>
          Testimonials
        </Typography>
        <Typography variant="body1" sx={{ color: 'text.secondary' }}>
          We're grateful for the trust our partners place in us. Here are some of their stories and experiences working with us.
        </Typography>
      </Box>
      <Grid container spacing={2}>
        {userTestimonials.map((testimonial, index) => (
          <Grid item xs={12} sm={6} md={4} key={index} sx={{ display: 'flex' }}>
            <Card
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                flexGrow: 1,
                p: 1,
              }}
            >
              <CardContent>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  {testimonial.testimonial}
                </Typography>
              </CardContent>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  pr: 2,
                }}
              >
                <CardHeader
                  avatar={testimonial.avatar}
                  title={testimonial.name}
                  subheader={testimonial.occupation}
                />
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}
